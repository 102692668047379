import {
  format,
  startOfMonth,
  endOfMonth,
  endOfYear,
  startOfYear,
  startOfWeek,
  endOfDay,
  endOfWeek,
  startOfDay,
} from 'date-fns';

export function getStartDate(dateRange) {
  const today = new Date();

  switch (dateRange) {
    case 'today':
      return format(startOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss');
    case 'yesterday':
      return format(startOfDay(new Date(new Date().setDate(today.getDate() - 1))), 'yyyy-MM-dd HH:mm:ss');
    case 'thisWeek':
      return format(startOfWeek(today, { weekStartsOn: 1 }), 'yyyy-MM-dd HH:mm:ss');
    case 'pastWeek':
      return format(
        startOfWeek(new Date(new Date().setDate(today.getDate() - 7)), { weekStartsOn: 1 }),
        'yyyy-MM-dd HH:mm:ss',
      );
    case 'last7Days':
      return format(startOfDay(new Date(new Date().setDate(today.getDate() - 7))), 'yyyy-MM-dd HH:mm:ss');
    case 'last28Days':
      return format(startOfDay(new Date(new Date().setDate(today.getDate() - 28))), 'yyyy-MM-dd HH:mm:ss');
    case 'last90Days':
      return format(startOfDay(new Date(new Date().setDate(today.getDate() - 90))), 'yyyy-MM-dd HH:mm:ss');
    case 'last12Months':
      return format(startOfMonth(new Date(new Date().setMonth(today.getMonth() - 12))), 'yyyy-MM-dd HH:mm:ss');
    case 'thisMonth':
      return format(startOfMonth(today), 'yyyy-MM-dd HH:mm:ss');
    case 'lastMonth':
      return format(startOfMonth(new Date(new Date().setMonth(today.getMonth() - 1))), 'yyyy-MM-dd HH:mm:ss');
    case 'thisYear':
      return format(startOfYear(today), 'yyyy-MM-dd HH:mm:ss');
    case 'lastYear':
      return format(startOfYear(new Date(new Date().setFullYear(today.getFullYear() - 1))), 'yyyy-MM-dd HH:mm:ss');
    case 'allTime':
      return format(startOfDay(new Date(new Date().setFullYear(today.getFullYear() - 5))), 'yyyy-MM-dd HH:mm:ss');
    default:
      return format(startOfDay(new Date(new Date().setDate(today.getDate() - 90))), 'yyyy-MM-dd HH:mm:ss');
  }
}

export function getEndDate(dateRange) {
  const today = new Date();

  switch (dateRange) {
    case 'today':
      return format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss');
    case 'yesterday':
      return format(new Date(new Date().setDate(today.getDate() - 1)), 'yyyy-MM-dd HH:mm:ss');
    case 'thisWeek':
      return format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss');
    case 'pastWeek':
      return format(
        endOfWeek(new Date(new Date().setDate(today.getDate() - 7)), { weekStartsOn: 1 }),
        'yyyy-MM-dd HH:mm:ss',
      );
    case 'last7Days':
      return format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss');
    case 'last28Days':
      return format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss');
    case 'last90Days':
      return format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss');
    case 'last12Months':
      return format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    case 'thisMonth':
      return format(endOfMonth(new Date()), 'yyyy-MM-dd HH:mm:ss');
    case 'lastMonth':
      return format(endOfMonth(new Date(new Date().setMonth(today.getMonth() - 1))), 'yyyy-MM-dd HH:mm:ss');
    case 'thisYear':
      return format(endOfYear(today), 'yyyy-MM-dd HH:mm:ss');
    case 'lastYear':
      return format(endOfYear(new Date(new Date().setFullYear(today.getFullYear() - 1))), 'yyyy-MM-dd HH:mm:ss');
    case 'allTime':
      return format(new Date(new Date().setFullYear(today.getFullYear() + 5)), 'yyyy-MM-dd HH:mm:ss');
    default:
      return format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss');
  }
}
